<template>
  <section
    :id="slice.primary.reference"
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="parent"
    :class="{'thirds-grid': !hideTitleArea, 'mosaic': mosaic}"
  >
    <div class="clamped">
      <div class="feature" v-if="!hideTitleArea">
        <PrismicRichText :field="slice.primary.title" class="heading-section" />
        <PrismicRichText :field="slice.primary.description" />
      </div>

      <div class="grid">
        <div v-for="(item, indexNum) in slice.items" :key="`${indexNum}-grid`" class="grid-item">
          <div class="heading-block">
            <PrismicImage v-if="item.icon && item.icon.url" :field="item.icon" class="icon" height="65" width="65" />
            <PrismicRichText :field="item.heading" class="heading" />
          </div>
          <PrismicRichText :field="item.text" class="copy" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getSliceComponentProps } from '@prismicio/vue/components';

export default {
  name: 'IconTextGrid',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    hideTitleArea() {
      return this.slice.variation === 'noTitleArea';
    },
    mosaic() {
      return this.slice.variation === 'mosaic';
    },
    gridItems() {
      const arrayIndex = [1, 2, 3, 4, 5, 6];
      let maxIndex = 0;

      for (const i in this.slice.items) {
        arrayIndex.forEach(index => {
          if (this.slice.items[i][`column_${index}`]?.[0] && index > maxIndex) {
            maxIndex = index;
          }
        });
      }

      return arrayIndex.slice(0, maxIndex);
    }
  }
};
</script>

<style lang="scss" scoped>
.parent {
  background-color: $very-light-gray;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

.thirds-grid {
  padding-block: 1em;
  display: grid;
  grid-gap: 2em;

  @include for-desktop() {
    grid-template-columns: 1fr 2fr;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 2em;
  font-size: $font-size-body;
}

.mosaic {
  &.thirds-grid {
    display: block;
  }
  .grid {
    column-count: 3;
    column-gap: 2rem;
    display: block;

    @media screen and (max-width: $large) {
      column-count: 2;
    }

    @media screen and (max-width: $medium) {
      column-count: 1;
      > .grid-item {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  .grid-item {
    display: inline-block;
    width: 100%;
    break-inside: avoid;
    margin-bottom: 1rem;
  }
}

.icon {
  border-radius: 50%;
  overflow: hidden;
  margin: 0.25rem;
  border: 2px solid $navy;
}

.heading-section :is(h1, h2, h3, h4, h5, h6) {
  line-height: 1.3;
}

.heading-block {
  ::v-deep.heading :is(h1, h2, h3, h4, h5, h6) {
    line-height: 1.2;
    font-size: 1.30rem;
    font-weight: 800;
    font-family: $primary-font;
  }

  ::v-deep.heading h3::after {
    content: '•';
    font-size: 1rem;
    color: $color-yellow;
    padding-left: 0.5rem;
  }
}

.heading-block {
  display: flex;
  gap: 1em;
  align-items: center;
}

// Style a differently if it's alone
.copy p:last-child > a:first-child:last-child {
  text-decoration: underline;
  text-underline-offset: 3px;
}
</style>
